<template>
  <section>
    <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-8">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">{{ $t('creator.titleDesc') }}</h1>
            <p>{{$t('creator.titleDescDesc')}}</p>
          </b-col>
          <b-col class="col-12"> 
            <b-form-input :state="state_title" v-model="title" class="mt-2" @keyup.enter="title.length > 0 ? save(): ''"  aria-describedby="input-live-help invalid-feedback-title"></b-form-input>
            <b-form-invalid-feedback id="invalid-feedback-title">
              <span v-if="title && title.length > 150">{{ $t('creator.150characters') }}</span>
            </b-form-invalid-feedback>

            <b-form-textarea :state="state_description" v-model="description" rows="1" max-rows="15" class="mt-2" :placeholder="$t('creator.descriptionCasting')" aria-describedby="input-live-help invalid-feedback-description"></b-form-textarea>
            <b-form-invalid-feedback id="invalid-feedback-description">
              <span v-if="description && description.length > 2000">{{ $t('creator.2000characters') }}</span>
            </b-form-invalid-feedback>
          </b-col>
          <hr class="my-2">
          <b-col class="col-12 d-flex justify-content-center">
            <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="!state_title || !state_description" @click="save()">{{$t('creator.savePreview')}}</b-button>
          </b-col>
        </b-row>
      </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';

export default {
  name: 'titleDescription',
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
  },
  data() {
    return {
      title: null,
      description: null,
    }
  },
  computed: {
    proposal() {
      return this.$route.params.proposal
    },
    state_title() {
      if (this.title) return this.title.length < 150
      else return false
    },
    state_description() {
      if (this.description) return this.description.length > 10 && this.description.length < 2000
      else return false
    },
  },
  created() {
    this.title = this.proposal.title;
    this.description = this.proposal.description
  },
  methods: {
    save() {
      proposals_services.updateProposal(this.proposal.uuid, {description: this.description, title: this.title}).then((response) => {
        window.history.pushState({}, null, `/view-proposal/${response.slug}`);
        this.$route.params.proposal = response;
        this.updateProposal(response);
      });
    },
    updateProposal(proposal) {
      this.$emit('update_proposal', proposal);
    },
  },
}
</script>